@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "placeholder-loading/dist/css/placeholder-loading.css";

/* .ph-border:1px solid darken($ph-bg, 10%) !default; */
.ph-item {
  border: none !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
}

.rag-green-outer {
  background-color: rgb(156, 250, 156) !important;
}

.rag-red-outer {
  background-color: rgb(224 75 75) !important;
}

.rag-gray-outer {
  background-color: #e7e8e9 !important;
}
.rag-gray-outer-esdashboard {
  background-color: #e7e8e9 !important;
  font-weight: bold;
}

.rag-selected-outer {
  background-color: rgb(188 223 252) !important;
}

.ag-theme-alpine .ag-tabs {
  min-width: 270px !important;
}

:root {
  /* style variables for VBPI 4.0 */
  /* ScriptMed texts color*/
  --dark-blue-text: #003A5F;
  --primary-blue-text: #0081C6;
  --primary-text: #676767;
  --primary-disabled-text: #C4C4C4;
  --primary-highlight-text: #CC4545;

  /* headings font sizes */
  --heading-font-size1: 1.5rem;
  /* 24px */
  --heading-font-size2: 1.25rem;
  /* 20px */
  --heading-font-size3: 1.125rem;
  /* 18px */
  --heading-font-size4: 1rem;
  /* 16px */
  --heading-font-size5: 0.875rem;
  /* 14px */

  /* Headings fonts*/
  --heading1: normal normal 600 var(--heading-font-size1) "Source Sans Pro";
  --heading2: normal normal 600 var(--heading-font-size2) "Source Sans Pro";
  --heading3: normal normal 600 var(--heading-font-size3) "Source Sans Pro";
  --heading4: normal normal 600 var(--heading-font-size4) "Source Sans Pro";
  --heading5: normal normal 600 var(--heading-font-size5) "Source Sans Pro";

  --heading1-bold: normal normal bold var(--heading-font-size1) "Source Sans Pro";
  --heading2-bold: normal normal bold var(--heading-font-size2) "Source Sans Pro";
  --heading3-bold: normal normal bold var(--heading-font-size3) "Source Sans Pro";
  --heading4-bold: normal normal bold var(--heading-font-size4) "Source Sans Pro";
  --heading5-bold: normal normal bold var(--heading-font-size5) "Source Sans Pro";




  /* body font sizes */
  --body-font-size1: 0.875rem;
  /* 14px */
  --body-font-size2: 0.75rem;

  --body-font-size3: 0.73rem;
  /* 12px */
  /* body fonts*/
  --body-font1: normal normal normal var(--body-font-size1) sans-serif;
  --body-font2: normal normal normal var(--body-font-size2) sans-serif;
  --body-font3: normal normal normal var(--body-font-size3) sans-serif;
  --body-semibold1: normal normal 600 var(--body-font-size1) sans-serif;
  --body-semibold2: normal normal 600 var(--body-font-size2) sans-serif;
}

/*Added mat tooltip styling*/
.mat-tooltip.tooltip {
    background-color: darkblue;
    font-size: 12px;
  }

@-webkit-keyframes ph-animation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }

  100% {
    transform: translate3d(30%, 0, 0);
  }
}

@keyframes ph-animation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }

  100% {
    transform: translate3d(30%, 0, 0);
  }
}

/*Added mat tooltip styling*/
.mat-tooltip.tooltip {
  background-color: darkblue;
  font-size: 12px;
}


.mat-icon{
  overflow: visible !important;
}

.io-primary-header{
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #4d4d4d !important;
  padding: 16px 16px 0 !important;
  margin: 0 !important;
}